<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <loading v-if="loader"/>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">All Jobs</h4>
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-6 col-md-3">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <!-- <label class="d-inline-flex align-items-center">
                                      Show
                                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                                    </label> -->
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <div class="col-sm-10">
                                </div>
                            </div>
                            <!-- Sete Status -->
                            <div class="col-sm-6 col-md-3">
                                <div class="col-sm-10">
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-6 col-md-3">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="alljobs"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >
                                <template v-slot:cell(type)="job">
                                    {{job.item.type == 1 ? "1-Day Booking": "Multiple day Booking"}}
                                </template>
                                <template v-slot:cell(start_time)="job">
                                    {{getDateWithTimeZone(job.item.start_time)}}&nbsp;
                                </template>
                                <template v-slot:cell(end_time)="job">
                                    {{getDateWithTimeZone(job.item.end_time)}}&nbsp;
                                </template>
                                <template v-slot:cell(actions)="job">
                                    <button class="btn btn-primary btn-sm" @click="job_details(job)">Details
                                    </button>&nbsp;
                                    <button class="btn btn-danger btn-sm" :disabled="checkCancelJob(job.item.status)"
                                            @click="cancelJob(job.item.id)">Cancel Job
                                    </button>&nbsp;
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <pagination :data="pagination" @pagination-change-page="getJobs"
                                                    :limit="limit"></pagination>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import loading from "../animation/loading";
    import appConfig from "@/app.config";
    import {jobsMethods} from "@/state/helpers";
    import pagination from "laravel-vue-pagination";
    // import moment from 'moment';

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "Jobs",
            meta: [{name: "description", content: appConfig.description}]
        },
        components: {Layout, PageHeader, pagination, loading},
        data() {
            return {
                loader: false,
                limit: 5,
                title: "Jobs",
                items: [
                    {
                        text: "Any",
                        to: "/jobs"
                    },
                    {
                        text: "Jobs",
                        href: "/"
                    },
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 25,
                // pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "id",
                sortDesc: true,
                fields:
                    [
                        {key: "title", label: 'Title', sortable: false},
                        {key: "type", label: 'Type', sortable: false},
                        {key: "total_workers", label: 'Workers'},
                        {key: "status", label: 'Status'},
                        {key: "start_time", label: 'Start Time', sortable: true},
                        {key: "end_time", label: 'end Time', sortable: true},
                        {key: "actions", sortable: false},
                    ]
            };
        },
        computed: {
            rows() {
                return this.$store.state.jobs.jobs.length;
            },
            alljobs() {
                return this.$store.state.jobs.jobs
            },
            pagination() {
                return this.$store.state.jobs.pagination
            }
        },
        watch: {
            currentPage: {
                handler: function () {
                }
            }
        },
        mounted() {
            this.getJobs();
        },
        created() {

        },
        methods: {
            ...jobsMethods,
            getTimeZone() {
                return Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            checkCancelJob(status) {
                if (status === 'Cancelled') {
                    return true;
                }
                if (status === 'Completed') {
                    return true;
                }
                if (status === 'Start') {
                    return true;
                }
                return false
            },
            getDateWithTimeZone(date) {
                return date;
                // let timeZone = this.getTimeZone();
                // let a = this.$moment.tz(date, timeZone);
                // let d = new Date(a._d);
                // let day = d.getDate().toString();
                // let getDate = day.length === 1 ? "0" + day : day
                // let month = (d.getMonth() + 1).toString();
                // let getMonth = month.length === 1 ? "0" + month : month
                // let seconds = (d.getSeconds()).toString();
                // let getSeconds = seconds.length === 1 ? "0" + seconds : seconds
                // let minutes = (d.getMinutes()).toString();
                // let getMinutes = minutes.length === 1 ? "0" + minutes : minutes
                // let hours = (d.getHours()).toString();
                // let getHours = hours.length === 1 ? "0" + hours : hours
                //
                // return d.getFullYear() + "-" + getMonth + "-" + getDate + " " +
                //     getHours + ":" + getMinutes + ":" + getSeconds;
            },
            getJobs(page = 1) {
                this.loader = true;
                this.getAllJobs({page: page}).then(res => {
                    if (res.status === 200) {
                        this.loader = false
                    }
                }).catch(error => {
                    this.loader = false;
                    console.log(error)
                    this.$swal({
                        icon: 'error',
                        toast: true,
                        text: "Session Expired! Please login again.",
                        showCloseButton: false,
                        showConfirmButton: false,
                        timer: 3000
                    });
                    this.unauthorizedError(error)
                })
            },
            cancelJob(id) {
                this.$swal({
                    icon: 'warning',
                    text: 'Are you sure to cancel',
                    showCancelButton: true,
                    toast: true,
                    confirmButtonColor: 'green',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then(result => {
                    if (result.value) {
                        console.log("id", id);
                        this.msg = '';
                        this.cancelEmployerJob({id: id}).then(res => {
                            if (res.status === 200) {
                                this.msg = res.message ? res.message : "Job has been cancelled."
                                this.$swal({
                                    icon: 'success',
                                    text: this.msg,
                                    toast: true,
                                    showCloseButton: false,
                                    showConfirmButton: false,
                                    timer: 3000
                                });
                            }
                        }).catch(error => {
                            this.unauthorizedError(error)
                        });
                    }
                });
            },
            // getDateWithTimeZone(date){
            //   return this.$moment(date).format('YYYY-MM-DD HH:mm:ss')
            // },
            unauthorizedError(err) {
                if (err.data.status === 401) {
                    return this.$router.push('/logout');
                }
                if (err.data.status === 400) {
                    this.errorMessage(err.data.message)
                }
                if (err.data.status === 500) {
                    this.errorMessage('Failed! error')
                }
            },
            errorMessage(message) {
                this.$swal({
                    icon: 'error',
                    text: message,
                    showCloseButton: true,
                    showConfirmButton: false,
                    timer: 3000
                });
            },
            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            job_details(job) {
                this.$router.push({name: 'job_details', params: {job_details: job.item, job_title: job.item.title}})
            }
        }
    };
</script>
